.title {
  font-weight: bold;
  max-width: 652px;
  font-size: 40px;
  line-height: 57px;
  text-align: center;
  margin: 0 auto 10px;

  @media (max-width: 575px) {
    font-size: 30px;
    line-height: 47px;
  }
}
