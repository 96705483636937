.filter {
  position: absolute;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 14.58%, rgba(56, 56, 56, 0.84) 100%);
}

.features_item {
  position: relative;
  margin: 15px;
  flex: 0 0 331px;
  height: 452px;

  @media (max-width: 1444px) {
    flex: 0 0 460px;
  }

  @media (max-width: 575px) {
    flex: 0 0 290px;
    height: 350px;
  }

  &__image {
    height: 100%;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
    }
  }

  &__content {
    position: absolute;
    z-index: 10;
    left: 20px;
    bottom: 61px;
    max-width: 250px;
  }

  &__subtitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 15px;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
  }
}
