.appbar_wrap .appbar {
  background-color: transparent;
  box-shadow: none;
  margin-bottom: 20px;
}

.appbar button:hover {
  box-shadow: none;
  background-color: transparent;
}

.appbar button:nth-child(2) > span:nth-child(1) span {
  background-color: #3751ff;
  min-width: 10px;
  height: 12px;
}

.verticle_divider {
  width: 1px;
  height: 40px;
  border: 1px solid var(--lavender-pinocchio);
  margin: 0 20px;
}

.appbar .avatar_img {
  padding: 2px !important;
  margin-left: 10px;
  border: 1.5px solid var(--lavender-pinocchio);
}

.app_username {
  max-width: 105px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width: 900px) {
  .appbar_wrap header > div {
    align-items: flex-end;
    padding-left: 0;
    flex-direction: column-reverse;
  }
  .appbar_wrap h6 {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 390px) {
  .appbar_wrap button {
    padding: 2px;
  }
}

@media screen and (max-width: 355px) {
  .appbar_wrap header div {
    padding-left: 0;
    padding-right: 0;
  }
  .verticle_divider {
    margin: 0 14px;
  }
}
