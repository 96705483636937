.testimonials_item {
  padding: 30px 35px;
  background: #FFFFFF;
  border: 1px solid #DEDEDE;
  margin: 15px 0;
  border-radius: 5px;
  flex: 0 0 330px;

  @media (max-width: 1049px) {
    margin: 15px;
  }

  @media (max-width: 575px) {
    flex: 0 0 290px;
  }

  &__stars {
    margin-bottom: 20px;
  }

  &__text {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #737373;
    margin-bottom: 20px;
  }
}

.testimonials_author {
  display: flex;
  align-items: center;

  &__image {
    margin-right: 15px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__name {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #23A6F0;
  }

  &__position {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #252B42;
  }
}
