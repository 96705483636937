.savetime_item {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  background: #FAFAFA;
  border: 1px solid #E9E9E9;
  border-radius: 5px;
  flex: 0 0 328px;
  margin: 15px 0;

  @media (max-width: 1049px) {
    margin: 15px;
  }

  @media (max-width: 575px) {
    margin: 15px 0;
    flex: 0 0 290px;
  }

  &__central {
    composes: savetime_item;
    background: #9B51E0;
    flex: 0 0 329px;

    @media (max-width: 575px) {
      flex: 0 0 290px;
    }
  }

  &__image {
    width: 48px;
    height: 40px;
    margin-bottom: 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    &__central {
      composes: savetime_item__text;
      color: #FFFFFF;
      max-width: 194px;
    }
  }
}
