.sidebar > div {
  padding: 30px 0px;
}

.sidebar_wrap {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar_wrap > img {
  width: 165px;
}

.menulist_wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 55vh;
  overflow-y: auto;
}

.sidebar ::-webkit-scrollbar {
  width: 2px;
}

.sidebar_logo {
  padding: 0 20px;
}

.sidebar .active_list {
  background: var(--santa-gray-light);
  border-left: 3px solid var(--tealish-blue);
  color: var(--tealish-blue);
}

.nonactive_list {
  color: var(--grey-chateau);
}

.sidebar .menulist {
  padding-top: 0;
  padding-bottom: 0;
}

.sidebar .sidebar_divider {
  padding: 0px 0;
  border-color: var(--lavender-pinocchio);
  opacity: 0.1;
  margin-top: 5px;
}

html .mb_appbar {
  background-color: transparent;
  padding: 10px 0;
  box-shadow: none;
}

.mb_appbar button {
  margin: 0;
  color: var(--purple-blue);
  padding: 5px 10px;
  margin-top: 1px;
}

@media screen and (max-height: 625px) {
  .sidebar_wrap {
    height: 88vh;
  }
}
@media screen and (max-width: 900px) {
  html .mb_appbar {
    width: max-content;
    margin-left: 0;
    left: 20px;
    position: absolute;
    top: 18px;
  }
  .mb_appbar button {
    display: flex;
    justify-content: flex-end;
  }
}
