.table_wrap::-webkit-scrollbar {
  height: 2px;
  width: 2px;
}

.table_pagination p {
  color: var(--santa-gray);
  font-size: 0.875rem;
  font-weight: 400;
}

.order_table_wrapper div:nth-child(1) h1 {
  color: var(--baltic-sea);
  font-size: 1.18rem;
}
.order_table_wrapper div:nth-child(2) button {
  color: var(--mulled-wine);
  font-size: 0.875rem;
}

html .order_table_wrapper th {
  font-size: 0.875rem;
  letter-spacing: 0.2px;
  color: var(--santa-gray);
  font-weight: 700;
}

html .order_table_wrapper tr:hover {
  background-color: rgb(55 81 255 / 4%) !important;
}

html .order_table_wrapper td {
  padding: 22px 16px;
}

html .order_table_wrapper td h2 {
  letter-spacing: 0.2px;
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--baltic-sea);
  font-weight: 600;
}

html .order_table_wrapper td p {
  color: var(--silver);
  font-weight: 400;
  margin-top: 5px;
}

@media screen and (max-width: 880px) {
  .order_table_wrapper table {
    width: max-content;
  }
}

@media screen and (max-width: 600px) {
  .order_table_wrapper > div:nth-child(1) {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 440px) {
  html .order_table_wrapper td,
  html .order_table_wrapper th {
    padding: 16px 20px;
  }
  .order_table_wrapper > div:nth-child(1) {
    padding: 16px 20px;
  }
}

@media screen and (max-width: 420px) {
  .table_pagination {
    margin-top: 20px;
  }
  .table_pagination > div {
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 380px) {
  .order_table_wrapper div:nth-child(1) h1 {
    font-size: 1rem;
  }
  .order_table_wrapper div:nth-child(2) button {
    font-size: 0.775rem;
  }
}

@media screen and (max-width: 345px) {
  .order_table_wrapper div:nth-child(1) h1 {
    font-size: 0.8rem;
  }
  .order_table_wrapper div:nth-child(2) button {
    font-size: 0.775rem;
  }
}
