.header {
  background-color: #FAFAFA;
}


.header_row {
  height: 91px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.header_menu {

  &__list {
    display: flex;
    margin-left: 225px;

    @media (max-width: 991px) {
      margin-left: 0;
    }
  }

  &__link {
    margin-right: 21px;
    font-weight: 600;
    line-height: 24px;
    color: #737373;

    &:last-child {
      margin-right: 0;
    }

    @media (min-width: 992px) {
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    @media (max-width: 767px) {
      margin-right: 15px;
    }
  }
}

.header_actions {
  &__login {
    background: -webkit-linear-gradient(180deg, #D71489, #004788);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    margin-right: 45px;

    @media (min-width: 992px) {
      &:hover {
        cursor: pointer;
        background: -webkit-linear-gradient(360deg, #D71489, #004788);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    @media (max-width: 767px) {
      margin-right: 35px;
    }
  }

  &__signup {
    display: inline-flex;
    align-items: center;
    font-weight: bold;
    padding: 0 25px;
    color: #ffffff;
    height: 52px;
    background: linear-gradient(270deg, #D71489 -1.04%, #004788 100%);
    border-radius: 5px;
    transition: all .3s linear;

    img {
      margin-left: 15px;
      width: 12px;
      height: 10px;
    }

    @media (min-width: 992px) {
      &:hover {
        cursor: pointer;
        color: #D71489;
      }
    }
  }
}

.header_icon {
  display: none;
}

@media (max-width: 575px) {

  .header_icon {
    z-index: 5;
    display: block;
    position: relative;
    width: 23px;
    height: 14px;
    cursor: pointer;

    span,
    &::before,
    &::after, {
      height: 14%;
      position: absolute;
      transition: all .3s linear;
      background-color: #737373;
    }

    &::before,
    &::after {
      content: "";
    }

    &:before {
      left: 0;
      top: 0;
      width: 100%;
    }

    &:after {
      bottom: 0;
      right: 0;
      width: 50%;
    }

    span {
      top: 50%;
      right: 0;
      width: 75%;
      transform: scale(1) translate(0, -50%);
    }

    &__active {
      composes: header_icon;

      span {
        transform: scale(0);
      }

      &:before {
        top: 50%;
        transform: rotate(-45deg) translate(0, -50%);
      }

      &:after {
        bottom: 50%;
        transform: rotate(-45deg) translate(0, 50%);
      }

      &:after {
        bottom: 0;
        right: 0;
        width: 50%;
      }
    }

  }

  .header_menu {
    &__list {
      position: fixed;
      display: block;
      top: 0;
      left: -200%;
      width: 100%;
      height: 100%;
      background-color: #FAFAFA;
      padding: 150px 30px 30px 30px;
      overflow: auto;
      transition: all .2s linear;
    }

    &__list__active {
      composes: header_menu__list;
      left: 0;
    }

    &__link {
      padding: 10px 10px 10px 0;
      font-size: 18px;
    }
  }

  .header_actions {
    position: fixed;
    top: 0;
    left: -200%;
    padding: 80px 30px 30px 30px;
    overflow: auto;
    transition: all .5s linear;

    &__active {
      composes: header_actions;
      left: 0;
    }
  }
}
