.early_access {
  background: #252B42;
  padding: 80px 0 100px;

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 991px) {
      flex-direction: column;
    }
  }

  &__content {
    @media (max-width: 991px) {
      margin-bottom: 30px;
      text-align: center;
    }
  }

  &__form {
    position: relative;
  }

  &__title {
    font-weight: bold;
    font-size: 40px;
    line-height: 57px;
    color: #FFFFFF;

    @media (max-width: 991px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;

    @media (max-width: 575px) {
      display: none;
    }
  }

  &__input {
    color: #737373;
    background: #F9F9F9;
    border: 1px solid #E6E6E6;
    padding: 15px 20px;
    border-radius: 5px 0 0 5px;
    height: 58px;
    width: 269px;

    @media (max-width: 575px) {
      width: 200px;
    }
  }

  &__btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    width: 117px;
    height: 58px;
    background: linear-gradient(270deg, #D71489 -1.04%, #004788 100%);
    border: 1px solid #E6E6E6;
    box-sizing: border-box;
    border-radius: 0 5px 5px 0;
    font-size: 14px;
    line-height: 28px;
    transition: all .2s linear;

    @media (min-width: 992px) {
      &:hover {
        color: #D71489;
        cursor: pointer;
      }
    }

    @media (max-width: 575px) {
      width: 90px;
    }
  }
}

.success {
  font-weight: 600;
  position: absolute;
  left: 0;
  bottom: -20px;
  color: green;
}

.error {
  font-weight: 600;
  position: absolute;
  left: 0;
  bottom: -20px;
  color: crimson;
}
