.form-name-input,
.od_input {
  border: 1px solid var(--soft-peach) !important;
  padding: 10px;
  border-radius: 6px;
}

.od_input:hover fieldset {
  border-color: transparent !important;
}

.od_input fieldset.Mui-focused {
  border-color: transparent !important;
}

.od_input label {
  transform: translate(0, -22px);
  font-size: 14px;
  font-weight: 600;
}

html .od_input {
  margin: 20px 0;
}

.MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

@media screen and (max-width: 600px) {
  .form-name-input {
    width: 100%;
  }
  .minWidth-100 {
    min-width: 100% !important;
  }
}
