.features {
  padding: 112px 0 71px 0;
  background: #FAFAFA;

  @media (max-width: 767px) {
    padding: 75px 0 60px 0;
  }

  &__row {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 81px;

    @media (max-width: 767px) {
      margin-top: 45px;
    }
  }
}
