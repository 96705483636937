.error_page {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.error_page h1 {
    font-size: 4rem;
}

.error_page a {
    margin-top: 20px;
}