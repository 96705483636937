.no_box {
  background: var(--white);
  border: 1px solid var(--lavender-pinocchio);
  border-radius: 8px;
  width: 220px;
  text-align: center;
  padding: 20px 0;
}

.no_box h2 {
  font-size: 1.18rem;
  font-weight: 700;
  letter-spacing: 0.4px;
  color: var(--santa-gray);
  margin-bottom: 5px;
}

.no_box strong {
  font-weight: 700;
  font-size: 2.5rem;
}

.mo-l-5 {
  margin-left: 30px;
}

.main-wrap .new_order_wrap {
  padding: 0;
  background-color: transparent;
  margin-bottom: 0;
}

.main-wrap .appbar_wrap h6 {
  display: none;
}

.main-wrap .new_order_wrap button {
  padding: 8px 20px;
  height: max-content;
  font-size: 1.1rem;
  background: var(--bluish-purple);
  border-radius: 8px;
  color: var(--white);
}

.new_order_wrap button img {
  width: 25px;
}

.new_order_wrap button:hover {
  background: var(--bluish-purple);
}

html .material-icons {
  width: unset;
  height: unset;
  display: flex;
}

.new_order_wrap .material-icons {
  margin-right: 10px;
}

.view_box {
  background: var(--white);
  border: 1px solid var(--lavender-pinocchio);
  border-radius: 8px;
  padding: 20px 0;
}

.vb_1 h2 {
  color: var(--baltic-sea);
  font-size: 1.18rem;
}

.vb_1 p {
  font-size: 0.75rem;
  color: var(--santa-gray);
}

.view_box a {
  color: var(--blue-orchid);
  text-decoration: none;
  letter-spacing: 0.2px;
  font-size: 0.875rem;
}

.vb_2 button {
  height: max-content;
}

.divider {
  border-bottom: 1px solid var(--lavender-pinocchio);
}

.vb_2 p {
  margin-right: 10px;
}

.vb_2 p,
.vb_2 label span {
  font-size: 0.875rem;
  color: var(--baltic-sea);
}

.vb_2 label span {
  user-select: none;
}

.vb_2 span {
  color: var(--santa-gray);
  font-size: 0.875rem;
}

.vb {
  padding: 10px 20px;
  min-height: 62px;
}

/* chart */

.chart_wrap {
  width: 100%;
  background-color: var(--white);
  border: 1px solid var(--lavender-pinocchio);
}

.chart_box {
  width: 70%;
  padding: 20px;
}

.chart_box canvas {
  min-height: 230px;
}

.chart_box h2 {
  font-size: 1.18rem;
  color: var(--baltic-sea);
}

.chart_box p {
  font-size: 0.75rem;
  color: var(--santa-gray);
}

.box_wrap {
  width: 30%;
  padding: 20px 0;
  border-left: 1px solid var(--lavender-pinocchio);
}

.month_box h2 {
  color: var(--santa-gray);
  font-size: 1rem;
}

.month_box p {
  color: var(--baltic-sea);
}

@media screen and (max-width: 1200px) {
  .chart_box {
    width: 70%;
  }
  .box_wrap {
    width: 30%;
  }
}

@media screen and (max-width: 1024px) {
  .no_box {
    width: 195px;
  }
  .chart_box {
    width: 65%;
  }
  .box_wrap {
    width: 35%;
  }
}

@media screen and (max-width: 1024px) {
  .no_box {
    width: 180px;
  }
  .no_box h2 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 800px) {
  .chart_box,
  .box_wrap {
    width: 100%;
  }
}

@media screen and (max-width: 670px) {
  .new_order_wrap {
    flex-direction: column;
  }
  .no_box {
    margin: 20px auto;
  }
  .new_order_wrap a {
    margin-top: 20px;
  }
}

@media screen and (max-width: 450px) {
  .vb {
    padding: 0;
  }
  .no_box {
    width: 46%;
  }
}

@media screen and (max-width: 425px) {
  .vb_2 span,
  .vb_2 p,
  .vb_2 label span {
    font-size: 0.775rem;
  }
}

@media screen and (max-width: 390px) {
  .no_box h2 {
    font-size: 0.9rem;
  }
  .no_box strong {
    font-size: 2rem;
  }
}
