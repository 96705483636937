@import "./assets/fonts/stylesheet";
* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px;
    &.lock {
        overflow: hidden;
    }
    &.lock_pop {
        overflow: hidden;
    }
}

input {
    padding: 0;
    margin: 0;
    &:focus {
        outline: none;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

p {
    margin: 0;
    padding: 0;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

button {
    border: none;
    &:focus {
        outline: none;
    }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

textarea {
    padding: 0;
    margin: 0;
    &:focus {
        outline: none;
    }
}