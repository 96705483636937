.pricing {
  background: #FAFAFA;
  padding: 112px 0 130px;

  @media (max-width: 767px) {
    padding: 75px 0 53px 0
  }

  &__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 91px;

    @media (max-width: 1049px) {
      justify-content: space-around;
    }

    @media (max-width: 767px) {
      margin-top: 45px;
    }
  }
}
