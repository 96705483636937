* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html body {
  background: var(--lavWhitelilac);
}

:root {
  --white: #ffffff;
  --purple-blue: #0d0c22;
  --tealish-blue: #dde2ff;
  --santa-gray: #9fa2b4;
  --santa-gray-light: #9fa2b417;
  --grey-chateau: #a4a6b3;
  --lavender-pinocchio: #dfe0eb;
  --lavWhitelilac: #f7f8fc;
  --silver: #c5c7cd;
  --bluish-purple: #591cdc;
  --baltic-sea: #252733;
  --blue-orchid: #3751ff;
  --orange-yellow: #fec400;
  --greenish-cyan: #29cc97;
  --porcelain: #f0f1f7;
  --mulled-wine: #4b506d;
  --deep-carmine-pink: #f12b2c;
  --cloud: #c4c4c4;
  --desert-storm: #f8f8f8;
  --soft-peach: #ededed;
  --boulder: #7a7a7a;
  --green: #68c660;
}

.form_input_light_bg {
  background-color: var(--desert-storm);
}

/* html a {
  text-decoration: none;
  color: initial;
} */

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

html .form-btn {
  min-width: 100px;
}

html .br-8 {
  border-radius: 8px;
}

html .br-100 {
  border-radius: 100px;
}

html .bg-orange-yellow,
html .bg-orange-yellow:hover {
  background-color: var(--orange-yellow) !important;
}

html .bg-greenish-cyan,
html .bg-greenish-cyan:hover {
  background-color: var(--greenish-cyan);
}

html .bg-porcelain,
html .bg-porcelain:hover {
  background-color: var(--porcelain);
}

html .bg-deep-carmine-pink,
html .bg-deep-carmine-pink:hover {
  background-color: var(--deep-carmine-pink);
}

html .bg-green,
html .bg-green:hover {
  background-color: var(--green) !important;
}

html .bg--purple-blue,
html .bg--purple-blue:hover {
  background-color: var(--purple-blue) !important;
}

html .text-white {
  color: var(--white) !important;
}

html .text-santa-gray {
  color: var(--santa-gray);
}

.error_msg {
  color: red;
}

.dashed-divider {
  width: 100%;
  margin: 20px 0;
  border: 1.5px dashed #ebebeb;
}

main {
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
}

html .form-wrap {
  max-width: 450px;
}

html .form-wrap > div {
  background: var(--white);
  border-radius: 5px;
  padding: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

html .form-link {
  color: #121212 !important;
  text-decoration: none !important;
  font-size: 1rem;
  font-weight: 500;
}

._main {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.date_wrap_order .full_input {
  width: 100% !important;
}

@media screen and (max-width: 600px) {
  .full_input {
    width: 100% !important;
  }
}

@media screen and (max-width: 450px) {
  .full_input {
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 425px) {
  html .form-btn {
    min-width: 82px;
  }
}
