.order_table_wrapper {
  min-height: 100vh;
}

.back_btn {
  position: absolute !important;
  bottom: 20px;
  right: 20px;
  color: var(--santa-gray);
  font-size: 1.125rem;
  letter-spacing: 0.3px;
}

html .order_details td {
  padding: 22px 16px 130px 16px;
}

.order_details > div > div > p {
  font-size: 1.1875rem;
  margin-right: 10px;
}

html .prder_prioSelect .priority_select {
  margin-left: 0;
}

@media screen and (max-width: 600px) {
  .order_details > div:nth-child(1) {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 440px) {
  .order_details > div:nth-child(1) {
    padding: 16px 20px;
  }
}

@media screen and (max-width: 425px) {
  .order_details > div > div > p {
    font-size: 1rem;
  }
}
