.infoblock {
    display: flex;
    justify-content: center;
    background-color: #FAFAFA;
    padding: 86px 15px 30px 15px;
    background-image: url(../../../../assets/images/smal-blob.svg);
    background-repeat: no-repeat;
    background-position: top 35px left 0;
    @media (max-width: 991px) {
        flex-wrap: wrap;
        background-position: top 100px left 0;
    }
    @media (max-width: 575px) {
        flex-wrap: wrap;
        background-position: top 200px left 0;
    }
}

.infoblock_content {
    @media (max-width: 991px) {
        text-align: center;
    }
    &__title {
        font-weight: 800;
        font-size: 58px;
        line-height: 80px;
        max-width: 509px;
        margin: 0 auto 35px;
        @media (max-width: 991px) {
            font-weight: 800;
            font-size: 50px;
            line-height: 60px;
            max-width: 500px;
        }
        @media (max-width: 575px) {
            font-size: 40px;
            line-height: 57px;
            max-width: 324px;
        }
    }
    &__text {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #737373;
        max-width: 448px;
        margin-bottom: 35px;
        @media (max-width: 991px) {
            margin: 0 auto 35px;
        }
        @media (max-width: 575px) {
            max-width: 280px;
        }
    }
    &__btn {
        height: 52px;
        display: inline-flex;
        align-items: center;
        padding: 0 36px;
        margin-bottom: 30px;
        font-weight: bold;
        background: linear-gradient(270deg, #D71489 -1.04%, #004788 100%);
        border-radius: 37px;
        color: #ffffff;
        transition: all .2s linear;
        @media (min-width: 992px) {
            &:hover {
                cursor: pointer;
                color: #D71489;
            }
        }
    }
}

.infoblock_image {
    margin-left: 50px;
    @media (max-width: 1140px) {
        margin-left: 0;
        flex: 0 0 390px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    @media (max-width: 575px) {
        flex: 0 0 300px;
        img {
            width: 100%;
            height: 100%;
        }
    }
}