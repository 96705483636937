.savetime {
  padding: 160px 0 65px 0;

  @media (max-width: 767px) {
    padding: 98px 0 65px 0
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 65px;

    @media (max-width: 1049px) {
      justify-content: space-around;
    }

    @media (max-width: 767px) {
      margin-top: 60px;
    }
  }
}
