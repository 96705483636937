.new_order_wrap {
  width: 100%;
  padding: 20px;
  background-color: var(--white);
  margin-bottom: 60px;
}

.neworder_top {
}

.neworder_top h2 {
  font-size: 1.5625rem;
  color: var(--baltic-sea);
}

.neworder_top p {
  font-size: 0.9375rem;
  color: var(--cloud);
}

.neworder_form_input {
  background-color: var(--desert-storm);
}

.new_order_wrap .neworder_form_input {
  width: 450px;
}

.new_order_wrap fieldset {
  /* border-color: transparent; */
}

html label,
html label:focus {
  color: var(--boulder) !important;
  font-size: 15px;
  font-weight: 700;
}

.fields_wrap h5 {
  font-size: 1rem;
  color: var(--baltic-sea);
}

.fw_1 {
  max-width: 820px;
  justify-content: space-between;
}

.form_field {
  /* margin: 15px 0 !important; */
}

@media screen and (max-width: 1135.2px) {
  .date_wrap {
    margin-top: 10px;
  }
}

@media screen and (max-width: 600px) {
  .new_order_wrap .neworder_form_input {
    width: 100% !important;
  }
}

@media screen and (max-width: 450px) {
  .date_wrap {
    flex-direction: column;
    width: 100%;
  }
}
