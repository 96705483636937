.pricing_item {
  text-align: center;
  background: #FFFFFF;
  border: 1px solid #DEDEDE;
  border-radius: 10px;
  padding: 50px 40px;
  margin: 30px 0;
  flex: 0 0 328px;

  @media (max-width: 1049px) {
    margin: 30px;
  }

  @media (max-width: 575px) {
    padding: 40px 20px;
    flex: 0 0 290px;
  }

  &__new {
    composes: pricing_item;
    position: relative;
    max-height: 418px;

    &::before {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      border-radius: 50%;
      top: 0;
      right: 0;
      transform: translate(50%, -30%);
      content: 'New';
      position: absolute;
      width: 90px;
      height: 90px;
      background-color: #E77C40;

      @media (max-width: 1199px) {
        transform: translate(-50%, -50%);
      }

      @media (max-width: 575px) {
        transform: translate(-20%, -40%);
      }
    }
  }

  &__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 35px;
  }

  &__subtitle {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #737373;
    margin: 0 auto 35px;
    max-width: 220px;

  }

  &__price {
    margin-bottom: 35px;

    p {
      font-weight: bold;
      font-size: 40px;
      line-height: 57px;
      color: #23A6F0;
    }

    span {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #8EC2F2;
    }
  }

  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #23A6F0;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
    height: 52px;
    width: 246px;
    transition: all .2s linear;
    margin-bottom: 35px;

    @media (min-width: 992px) {
      &:hover {
        background: #FFFFFF;
        color: #23A6F0;
        border: 2px solid #23A6F0;
        box-shadow: 5px 5px #23A6F0;
        cursor: pointer;
      }
    }
  }

  .pricing_item__checklist {

  }

  &__check {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    text-align: left;

  }
  &__check_image {
    flex: 0 0 32px;
    height: 32px;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__check_text {
    font-weight: 600;
    font-size: 14px;
    color: #252B42;
  }
}
