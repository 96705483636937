.modal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .8);
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: all .3s linear;

  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__label {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 0;
    color: #737373;

    @media (max-width: 575px) {
      font-size: 14px;
    }
  }

  &__input {
    display: block;
    background: #F9F9F9;
    border: 1px solid #E6E6E6;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px;
    margin-left: 10px;
  }

  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    padding: 10px 30px;
    background: linear-gradient(270deg, #D71489 -1.04%, #004788 100%);
    border: 1px solid #E6E6E6;
    box-sizing: border-box;
    border-radius: 5px;
    transition: all .2s linear;

    @media (min-width: 992px) {
      &:hover {
        cursor: pointer;
        color: #D71489;
      }
    }
  }
}

.modal__active {
  composes: modal;
  opacity: 1;
  pointer-events: all;
}

.modal_content {
  position: relative;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  transition: all .3s linear;
  transform: scale(0.5);

  @media (max-width: 575px) {
    padding: 10px;
  }
}

.modal_content__active {
  composes: modal_content;
  transform: scale(1);
}

.success {
  font-weight: 600;
  color: green;
}

.error {
  font-weight: 600;
  color: crimson;
}

.close {
  color: #737373;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(110%, 30%);
}
