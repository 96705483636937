.footer {
}

.footer_top {
  padding: 80px 0;
  display: flex;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 575px) {
    padding-bottom: 65px;
  }

  &__left {
    flex: 0 0 50%;

    @media (max-width: 991px) {
      text-align: center;
      margin-bottom: 30px;
    }
  }

  &__right {
    flex: 0 0 50%;
    display: flex;

    @media (max-width: 575px) {
      flex-direction: column;
      text-align: center;
    }
  }

  &__link {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #737373;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__column {
    flex: 0 0 50%;

    @media (max-width: 991px) {
      margin: 0 15px;
    }

    @media (max-width: 575px) {
      margin: 15px 0;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
    letter-spacing: 0.1px;
    color: #252B42;
  }

  &__subtitle {
    margin: 5px 0 25px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #737373;
    max-width: 225px;
  }

  &__socials {
    display: flex;

    @media (max-width: 991px) {
      justify-content: center;
    }
  }

  &__icon {
    display: block;
    flex: 0 0 24px;
    height: 24px;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.footer_bottom {
  padding: 25px 0;
  background: #FAFAFA;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #737373;
}
